<template>
  <div class="PageBox" :key="radio">
    <div class="header_bg">
      <div style="text-align: right;font-size: 16px;padding: 5px">
        <van-row>
          <van-col span="18" @click="gotoApp" style="text-align: left">
            {{$t('common5[6]')}}
          </van-col>
          <van-col span="6" @click="showLanguage=true">{{$t('login.text[0]')}}</van-col>
          <!-- <van-col span="6" @click="$router.push('/language')">{{$t('login.text[0]')}}</van-col> -->
        </van-row>
      </div>
    </div>

    <div class="topDiv">
      <img :src="`./skin/logo3.png`" width="160px" style="text-align: center"></img>
    </div>

    <div class="loginpanel">
      <div style="padding-top: 20px">
        <van-field
            type="text"
            autocomplete="off"
            style="height: 0; width: 0; padding: 0; position: absolute"
        />
        <van-field
            type="password"
            autocomplete="off"
            style="height: 0; width: 0; padding: 0; position: absolute"
        />

        <div class="label mt-10">{{$t('login.text1[2]')}}</div>
        <van-field
            :formatter="formatterUsername"
            :border="false"
            class="input"
            v-model.trim="postData.username"
            :placeholder="$t('login.placeholder[0]')"
            size="large"
            clearable
            autocomplete="off"
        />
        <div class="label">{{$t('login.text1[3]')}}</div>

        <van-field
            :border="false"
            class="input"
            v-model.trim="postData.password"
            :type="showPass ? 'text' : 'password'"
            :placeholder="$t('login.placeholder[1]')"
            size="large"
            clearable
            autocomplete="off"
            :right-icon="showPass ? 'eye' : 'closed-eye'"
            @click-right-icon="showPass = !showPass"
        />

      </div>


      <div style="margin:0 auto;text-align: center">
        <div class="centerPanel" style="width: 90%">
          <van-button @click="onSubmit()">{{ $t('login.text[2]') }}</van-button>
        </div>

        <div class="flex_sb ml-30" style="padding-top: 10px;font-size: 14px">

          <div style="color: var(--font_subtitle)">
            <router-link class="href" slot="a" to="/register">{{
                $t('login.default[1]')
              }}</router-link>
          </div>

          <div style="text-align: right;margin-right: 30px">
            <router-link class="href" to="/resetpwd">{{
                $t('login.default[2]')
              }}</router-link>
          </div>
        </div>
      </div>
    </div>

    <div style="position:relative;text-align:right;margin-top: 120px;margin-right: 20px" @click="gotoService">
      <van-icon name="./static/icon/customer4.png" size="55"/>
    </div>
    <van-action-sheet v-model="showLanguage" :title="$t('login.text[0]')">
      <div class="content">
        <div @click="changeLanguage('en-US',1)" class="l_item" :class="parseInt(radio)==1?'active_l_item':''">English</div>
        <div @click="changeLanguage('vn',2)" class="l_item" :class="parseInt(radio)==2?'active_l_item':''">Tiếng Việt</div>
        <div @click="changeLanguage('jp',4)" class="l_item" :class="parseInt(radio)==4?'active_l_item':''">日本語</div>
        <div @click="changeLanguage('ko-KR',5)" class="l_item" :class="parseInt(radio)==5?'active_l_item':''">한국어</div>
        <div @click="changeLanguage('zh-CN',3)" class="l_item" :class="parseInt(radio)==3?'active_l_item':''">简体中文</div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
export default {
  name: "Login",
  components: {},
  props: ["username"],
  data() {
    return {
      showLanguage:false,
      postData: {
        username: localStorage["UserName"] || "",
        password: localStorage["PassWord"] || "",
        uniqueID: localStorage["phoneId"] || "", //手机唯一标识
      },
      keepUser: localStorage["KeepUser"] == "1" ? true : false,
      isSubmit: false,
      showPass: false,
      language1: localStorage.getItem("Language") == "cn",
      service_url:'',
      radio:''
    };
  },
  computed: {},
  watch: {},
  created() {
    this.isSubmit = false;
    console.log(this.language1);

    this.selectLan()
  },
  mounted() {
    var query= this.$route.query;
    if (query["username"]){
      this.postData.username = query["username"];
    }
    this.$Model.GetBackData((data) => {
      var setting = data.setting;
      this.service_url = setting.service_url;
    });
  },
  activated() {},
  destroyed() {},
  methods: {
    gotoService(){
      window.location.href = this.service_url;
    },
    changeLanguage(lan,select) {
      this.radio = select;
      this.$SetLanguage(lan);
      this.$router.go(-1);
      this.selectLan();
    },
    selectLan() {
      let a = localStorage.getItem("Language");
      switch (a) {
        case "en":
          this.radio = "1";
          // console.log("11111");
          break;
        case "vn":
          this.radio = "2";
          break;
        case "cn":
          this.radio = "3";
          break;
        case "jp":
          this.radio = "4";
          break;
        case "kr":
          this.radio = "5";
          break;
      }
    },


    onSubmit() {
      if (!this.postData.username) {
        this.$Dialog.Toast(this.$t("login.placeholder[0]"));
        return;
      }
      if (!this.postData.password) {
        this.$Dialog.Toast(this.$t("login.placeholder[1]"));
        return;
      }
      this.isSubmit = true;
      this.$Model.Login(this.postData, (data) => {
        this.isSubmit = false;
        if (data.code==0){
          let idx = data.code_dec;
          let msg = this.$t("login.codes['"+idx+"']");
          this.$toast(msg);
          return;
        }
        var appData = {};
        try {
          var data = localStorage["AppData"];
          if (data) {
            appData = JSON.parse(data);
          }
        } catch (e) {
          // alert('3'+e.message)
          appData = {};
        }
        if (this.keepUser) {
          appData["UserName"] = this.postData.username;
          appData["PassWord"] = this.postData.password;
          localStorage["UserName"] = this.postData.username;
          localStorage["PassWord"] = this.postData.password;
        } else {
          appData["UserName"] = null;
          appData["PassWord"] = null;
          localStorage.removeItem("UserName");
          localStorage.removeItem("PassWord");
        }
        var data = JSON.stringify(appData);
        localStorage["AppData"] = data;
        var ret = this.$Util.writeAppStorage(data);
        this.$router.push("/");
      });
    },
    changeKeepUser() {
      var appData = {};
      try {
        var data = localStorage["AppData"];
        if (data) {
          appData = JSON.parse(data);
        }
      } catch (e) {
        // alert('3'+e.message)
        appData = {};
      }
      if (this.keepUser) {
        appData["KeepUser"] = 1;
        localStorage["KeepUser"] = 1;
      } else {
        appData["KeepUser"] = 0;
        localStorage.removeItem("KeepUser");
      }
      var data = JSON.stringify(appData);
      localStorage["AppData"] = data;
      this.$Util.writeAppStorage(data);
    },
    // 用户名格式化不能输入空格
    formatterUsername(value) {
      return value.replace(/\s*/g, "");
    },
    gotoApp(){
      window.location.href = this.InitData.setting.app_url;
    }
  },
};
</script>
<style scoped lang="scss">
.topDiv{
  width: 100%;
  height: 60px;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
}


.LoginHeader {
  padding: 0 0;
}

.PageBox {
  padding: 0;
  background-color: var(--main-bg-color);
}
.ScrollBox {

}

.loginpanel {
  margin: 0 auto;
  background: transparent;
  width: 90%;
  padding-bottom: 40px;
  position: relative;
  z-index: 11;
  top: 110px;
  border-radius: 16px;
}

.logo {
  margin: 50px auto;
  margin-left: 90px;
}

.loginpanel img {
  margin: 20px auto;
  text-align: center;
  width: 200px;
}

.header_bg {
  position: relative;
  width: 100%;
  z-index: 10;
  color: var(--font_nav_bar);
}

.active_l_item{
  color: var(--nav-bar-font);
}

.label{
  font-size: 16px;
  color: var(--font_subtitle);
  margin-left: 30px;
  margin-bottom: 20px;
}
.van-cell--borderless.input {
  width: 85%;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 25px;
  margin-left: 30px;
  padding: 0 30px 0 13px;
  height: 45px;

}
.van-cell ::v-deep .van-field__body {
  height: 100%;
}
.van-cell ::v-deep .van-icon-closed-eye {
  font-size: 16px;
  margin-top: 5px;
}

.van-cell--borderless ::v-deep .van-cell__value--alone {
  height: 100%;
}

.van-cell--borderless ::v-deep .van-checkbox__label {
  font-size: 13px;
  font-family: Alibaba;
  margin-top: -1px;
}

.van-cell--borderless ::v-deep .van-icon-success {
  border: 1px solid #ff93a4;
  box-shadow: 0px 0px 0px 0.5px #ff93a4;
  margin-left: 1px;
}


.van-cell--borderless ::v-deep .van-icon {
  font-size: 18px !important;
  color: var(--cell_placeholder) !important;
  // margin-top: 6px;
}

.van-cell--borderless.input ::v-deep .van-field__control {
  padding-left: 7px;
  height: 100%;
  font-size: 16px;
}

.van-cell--borderless.input ::v-deep .van-field__control::-webkit-input-placeholder {
  font-size: 16px;
  color: var(--cell_placeholder) !important;
  font-family: Alibaba;
}

.van-cell--borderless.input ::v-deep .van-field__control::-moz-placeholder {
  font-size: 19px;
  color: var(--cell_placeholder) !important;
  font-family: Alibaba;
}

.buttonPanel {
  margin: 0 auto;width: 90%;
  background-color: transparent;
  border-radius: 10px;
  padding: 15px;
  margin-top: 120px;
}

.buttonPanel .van-button{
  margin-bottom: 15px;
  font-size: 15px;
  color: var(--font_nav_bar);
}

.otherButton {
  text-align: center;line-height: 50px;margin: 10px;height: 60px;
  border-bottom: 1px dashed;
  margin-bottom: 30px;
}
.van-cell--borderless.input ::v-deep .van-field__control::-ms-input-placeholder {
  font-size: 18px;
  color: var(--cell_placeholder) !important;
  font-family: Alibaba;
}

.van-form {
  padding: 0 35px;
}

.van-button2 {
  background: transparent;
  border: 0px;
}

.van-button--danger {
  max-width: 200px;
  margin: auto;
  font-family: Alibaba;
  font-size: 20px !important;
  font-weight: bold;
  background: linear-gradient(90deg, #fff, #fff);
}

.van-cell--borderless ::v-deep .van-icon-manager,
.van-cell--borderless ::v-deep .van-icon-lock {
}

.van-cell--borderless ::v-deep .van-icon-manager::before,
.van-cell--borderless ::v-deep .van-icon-lock::before {
  font-size: 27px;
  margin-top: 4px;
}

.van-cell--large {
  padding-top: 17px;
  padding-bottom: 8px;
}

.Site .van-nav-bar ::v-deep .van-icon {
}

.van-nav-bar__text1 ::v-deep .van-nav-bar__text {
  letter-spacing: 0.09rem;
}

.PageBox ::v-deep .letter_s {
  letter-spacing: 0.08rem;
}

.href {
  color: var(--font_link);
  text-decoration: underline;
}

.PageBox a:link {
  color: var(--font_link);
}

.content{
  padding: 60px 0;
}
.l_item{
  font-size: 18px;
  padding: 10px ;
  text-align: center;
}

</style>
